<template>
  <section>
    <div class="mx-10">
      <v-col cols="12" md="12">
        <v-breadcrumbs large :items="breadcrumbsItems"></v-breadcrumbs>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          :items="obsTemplate"
          label="Plantilla de producto a utilizar*"
          outlined
          item-text="obs"
          return-object
          v-model="obsTemplateSelected"
          @change="setConfigurationView()"
        ></v-select>
      </v-col>

      <v-expand-transition>
        <v-col v-if="obsTemplateSelected" cols="12" md="6">
          <v-text-field
            v-model="obsTemplateSelected.title"
            label="Título"
            readonly
            outlined
          ></v-text-field>
        </v-col>
      </v-expand-transition>

      <v-expand-transition>
        <v-col cols="12" md="6" v-if="obsTemplateSelected">
          <v-textarea
            v-model="obsTemplateSelected.description"
            label="Descripción"
            readonly
            outlined
          >
          </v-textarea>
        </v-col>
      </v-expand-transition>

      <!--Componente para Presentaciones de OBS  -->
      <presentationsTable
        :obsTemplateSelected="obsTemplateSelected"
        :headers="headers"
        subtitle="Listado de presentaciones"
      />
      <v-row class="my-8">
        <v-col cols="12" sm="12" md="1" lg="1">
          <v-btn @click="$router.back()" block color="secondary" outlined>
            Regresar
          </v-btn>
        </v-col>
        <!-- <v-col cols="12" sm="12" md="1" lg="1">
          <v-btn block color="secondary"> Aceptar </v-btn>
        </v-col> -->
      </v-row>
    </div>
  </section>
</template>

<script>
import presentationsTable from "./components/PresentationsTableComponent.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "ofertarProductoView",
  components: {
    presentationsTable,
  },
  data: () => ({
    obsTemplateSelected: null,
    plantillaItems: [],
    headers: [
      {
        text: "Presentación",
        align: "center",
        sortable: false,
        width: "20%",
        value: "nombre_presentacion",
      },
      {
        text: "Unidad de venta",
        align: "center",
        sortable: false,
        width: "20%",
        value: "unidad_presentacion",
      },
      {
        text: "Detalle",
        align: "justify",
        sortable: false,
        width: "20%",
        value: "detalles",
      },
      {
        text: "Precio ($)",
        align: "center",
        sortable: false,
        width: "20%",
        value: "precio_unitario",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        width: "20%",
        value: "actions",
      },
    ],
  }),
  computed: {
    ...mapState("convenioMarcoProveedor", [
      "breadcrumbsItems",
      "currentAgreement",
    ]),
    obsTemplate() {
      if (this.plantillaItems) {
        let newPlantilla = this.plantillaItems.map((col) => ({
          id: col.id,
          obs: `${col.obs_codigo} - ${col.obs_nombre}`,
          obs_id: col.id,
          title: col.titulo,
          description: col.descripcion,
        }));

        newPlantilla.push({
          id: 0,
          obs: "Producto nuevo",
        });

        return newPlantilla.reverse();
      }
      return [];
    },
  },

  methods: {
    ...mapActions("convenioMarcoProveedor", [
      "getAgreementPresentations",
      "getCurrentAgreement",
    ]),

    setConfigurationView() {
      if (this.obsTemplateSelected.id == 0) {
        this.$router.push({
          name: "agregar-lista",
          params: { type: "OBS" },
        });
      }
    },

    async getTemplateObsList() {
      let response = await this.services.Proveedores.getProviderAgreementTags({
        id_convenio: this.currentAgreement.id,
      });
      if (response?.status == 200) {
        this.plantillaItems = response?.data;
      }
    },
  },
  async created() {
    await this.getCurrentAgreement(this.$route.params.id);
    if (this.breadcrumbsItems.length === 1) {
      this.breadcrumbsItems.push({
        text: this.currentAgreement.correlativo,
      });
    }
    this.breadcrumbsItems.push({ text: "Ofertar producto" });
    this.getTemplateObsList();
  },
  beforeDestroy() {
    this.breadcrumbsItems.pop();
  },
};
</script>
